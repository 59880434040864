new Swiper('#review', {
    slidesPerGroup: 1,
    initialSlide: 1,
    speed: 300,
    grabCursor: true,
    keyboard: {
        enabled: true,
        onlyInViewport: false,
    },
    breakpoints: {
        1: {
            slidesPerView: 1.1,  // 2.4
            spaceBetween: 10,
            centeredSlides: true,
        },
        600: {
            slidesPerView: 1.5,  // 2.4
            spaceBetween: 15,
            centeredSlides: true,
        },
        830: {
            slidesPerView: 2.1,  // 2.4
            spaceBetween: 15,
            centeredSlides: true,
        },
        1200: {
            slidesPerView: 2.7,  // 2.4
            spaceBetween: 15,
            centeredSlides: true,
        },
        1580: {
            slidesPerView: 2.5,  // 2.4
            spaceBetween: 15,
            centeredSlides: false,
        }
    }
})