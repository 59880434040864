import Notification from "@partials/UI/notifications/modules/Notification";
import imgSuccess from '@images/icons/success.svg';
import imgError from '@images/icons/error.svg';

const successForm = `
    <h5 class="note-title flex_c">
        <img class="note-img" src="${imgSuccess}" alt="Success">
        Мы получили сообщение
    </h5>
    <p class="note-text">Спасибо! Мы получили сообщение и свяжемся с вами в ближайшее время</p>
`;

const unsuccessForm = `
    <h5 class="note-title flex_c">
        <img class="note-img" src="${imgError}" alt="Error">
        Что-то пошло не так...
    </h5>
    <p class="note-text">Мы уже исправляем это! А пока свяжитесь с нам в мессенджерах или соц. сетях</p>
    <div class="socials s_mobile flex_c">
        <a class="icon vi_round" href="#" target="_blank"><i class="i_telegram"></i></a>
        <a class="icon vi_round" href="#" target="_blank"><i class="i_whatsapp"></i></a>
        <a class="icon vi_round" href="#" target="_blank"><i class="i_vk"></i></a>
    </div>
`;

function createInstanceNotification(content) {
    return new Notification({
        selector: '#notification',
        content: content,
        close: '#notification .close',
        overlay: '#notification .ov_offcanvas_center'
    })
}

export {
    successForm,
    unsuccessForm,
    createInstanceNotification
}